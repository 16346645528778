import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SnackBar } from './snack-bar';

@Component({
  selector: 'app-snack-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  protected bar = inject(SnackBar);
}
