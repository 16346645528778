import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, forwardRef, input, viewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '@components/spinner';
import { LoadingStatus } from '@features/common';
import { ControlValueAccessorBase } from '../control-value-accessor-base/control-value-accessor.base';

type ColorType = 'black-and-white';
type SizeType = 'xl' | '2xl';

type BorderRoundingType = 'md' | 'full';
@Component({
  selector: 'app-form-dropdown-field',
  standalone: true,
  imports: [CommonModule, CdkMenuModule, ReactiveFormsModule, SpinnerComponent],
  templateUrl: './form-dropdown-field.component.html',
  styleUrl: './form-dropdown-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDropdownFieldComponent),
      multi: true,
    },
  ],
})
export class FormDropdownFieldComponent<T> extends ControlValueAccessorBase<T> {
  controlId = input.required<string>();
  options = input.required<KeyValue<T, string>[] | undefined>();
  loadingStatus = input<LoadingStatus>(LoadingStatus.Loaded);
  required = input<boolean>(false);
  height = input<string>('3rem');
  placeholder = input.required<string>();
  color = input<ColorType>();
  rounded = input<BorderRoundingType>('md');
  fontSize = input<SizeType>();
  icon = input<string>('');
  iconSize = input<SizeType>();

  protected loadingStatusEnum = LoadingStatus;
  protected selectedOption = viewChild.required<ElementRef>('selectedOption');

  protected colorClasses = computed(() => {
    return this.color() === 'black-and-white'
      ? this.isDisabled()
        ? 'bg-colors.button.disabled text-black'
        : 'bg-white text-black'
      : this.isDisabled()
        ? 'bg-form-field-color bg-opacity-10 text-form-field-color'
        : 'bg-form-field-color bg-opacity-10 text-form-field-color';
  });

  protected triggerColorClasses = computed(() => {
    return this.color() === 'black-and-white'
      ? 'text-black'
      : this.isDisabled()
        ? 'text-neutral-600'
        : ' bg-opacity-10 text-form-field-color';
  });

  protected fontSizeClasses = computed(() => {
    return this.fontSize() === 'xl'
      ? 'text-xl font-bold'
      : this.fontSize() === '2xl'
        ? 'text-2xl font-bold'
        : 'text-md font-normal';
  });

  protected iconSizeClasses = computed(() => {
    return this.iconSize() === 'xl' ? 'text-xl' : 'px-2 text-2xl';
  });

  protected spinnerSize = computed(() => {
    return this.iconSize() === 'xl' ? 24 : 15;
  });

  protected onChange(key: T) {
    this.value = key;
    this.change(key);
  }

  protected getSelectedOption() {
    return this.options()?.find((option) => option.key === this.value)?.value;
  }

  protected onClick() {
    try {
      (this.selectedOption().nativeElement as HTMLElement).scrollIntoView();
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }
}
