import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-checkbox-field.component.html',
  styleUrl: './form-checkbox-field.component.scss',
})
export class FormCheckboxFieldComponent {
  control = input.required<FormControl>();
  name = input.required<string>();
  isDisabled = input<boolean>(false);
}
